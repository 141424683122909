import classes from './Banner7.module.css';


const Banner7=()=>{
    return <section className={classes.main}> 
            
    <h1>WAREHOUSE CONSTRUCTIONS</h1>
              
            </section>
};


export default Banner7;