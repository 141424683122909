import classes from './Banner1.module.css';


const Banner1=()=>{
    return <section className={classes.main}> 
            
    <h1>INDUSTRIAL CIVIL WORKS </h1>
              
            </section>
};


export default Banner1;