import React from 'react';
import classes from './Ques.module.css';

const Ques = () => {
  return (
    <div className={classes.qa}>
<div className={classes.aq}>  

<center>
    
<h3 className={classes.head}>OUR <span className={classes.heads}>TEAM</span></h3>

<p className={classes.p}>We are a bunch of highly enthusiastic individuals who are dedicated to providing reliable services that make us the best industrial construction companies in Pune and other nearby locations. In the journey of over a decade, we have come across different complicated projects that are challenging and demanding but our experienced and dedicated teammates never give up, with their constant efforts and expertise we always emerged as the market leaders with unconventional and cost-effective solutions for the structuring and designing need of different industries.

So if you are also looking for an expert platform for turnkey projects solutions or any other need in a similar category. Do connect with us without a second thought as we will only provide the best.</p>
</center>

</div>



    </div>
  )
}

export default Ques