import classes from './Banner8.module.css';


const Banner8=()=>{
    return <section className={classes.main}> 
            
    <h1>FACTORY SHED FABRICATION SERVICES</h1>
              
            </section>
};


export default Banner8;