import classes from './Banner3.module.css';


const Banner3=()=>{
    return <section className={classes.main}> 
            
    <h1>MACHINE FOUNDATIONS</h1>
              
            </section>
};


export default Banner3;