import classes from './Banner5.module.css';


const Banner5=()=>{
    return <section className={classes.main}> 
            
    <h1>ALUMINUM WORK (Facade System)</h1>
              
            </section>
};


export default Banner5;