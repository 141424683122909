import classes from './Banner2.module.css';


const Banner2=()=>{
    return <section className={classes.main}> 
            
    <h1>PEB & HEAVY FABRICATION WORKS </h1>
              
            </section>
};


export default Banner2;