import classes from './Hord.module.css';


const Hord=()=>{
    return <section className={classes.main}> 
            
    <h1>SERVICES</h1>
              
            </section>
};


export default Hord;