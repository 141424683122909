import classes from './Banner6.module.css';


const Banner6=()=>{
    return <section className={classes.main}> 
            
    <h1>PREFABRICATED SHEDS</h1>
              
            </section>
};


export default Banner6;