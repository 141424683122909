import classes from './Banner4.module.css';


const Banner4=()=>{
    return <section className={classes.main}> 
            
    <h1>TREEMAX FLOORING/ROAD CONSTRUCTION WORKS</h1>
              
            </section>
};


export default Banner4;