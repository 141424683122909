import classes from './Poster.module.css';


const Poster=()=>{
    return <section className={classes.main}> 
            
             
               
                <h1>ENQUIRE HERE</h1>
              
             

    </section>
};


export default Poster;