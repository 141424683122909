import classes from './Banner9.module.css';


const Banner9=()=>{
    return <section className={classes.main}> 
            
    <h1>INDUSTRIAL INTERIOR WORKS</h1>
              
            </section>
};


export default Banner9;