import React from 'react';
import classes from './Qual.module.css';

const Qual = () => {
  return (<div className={classes.qa}>
    <center>
      <div className={classes.aq}>

        <center>

          <h3 className={classes.head}>EXPERIENCE <span className={classes.heads}>COUNTS!!!</span></h3>

          <p className={classes.p}>When we talk about the construction segment then experience matters the most. Since 2011, we have invested plenty of time and resources to develop a team of highly skilled and reliable technicians that is capable of handling complicated projects with great ease. If you also want to take advantage of our experience and expertise then reach us quickly to get the best services at the most affordable rates. Here you will get all the necessary services under one roof, like-
            <ul className={classes.ddd}>

              <li className={classes.dd}> Plan approval</li>
              <li className={classes.dd}> Land development</li>
              <li className={classes.dd}> Civil work</li>
              <li className={classes.dd}> Machine foundation</li>
              <li className={classes.dd}> PEB structure</li>


            </ul>
          </p>
        </center>

      </div>
    </center>



  </div>
  )
}

export default Qual