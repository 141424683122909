import React from 'react';
import classes from './OurClient.module.css';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const OurClient = () => {
  return (
    <div className={classes.hold}>
<br/>
<Zoom> <h3 className={classes.head}>OUR <span className={classes.heads}>CLIENTS</span></h3>  </Zoom>


<div className={classes.box}>

<div className={classes.row}>
  
<div className={classes.dow}>
<ul className={classes.ddd}>
  
<li className={classes.dd}> Surecon Fastening & Engineering Pvt. Ltd</li>
<li className={classes.dd}> Shree Sai Samarth Coatings Pvt. Ltd.</li>
<li className={classes.dd}> Emm Ess Aircon Pvt. Ltd. </li>
<li className={classes.dd}> Tapovan Auto Tech Pvt. Ltd. </li>
<li className={classes.dd}> Gauri Forge</li>
<li className={classes.dd}> Sunil Kotian</li>
<li className={classes.dd}>  Parle-G  </li>
<li className={classes.dd}> Atlas Copco  </li>
<li className={classes.dd}>  Royal Enterprise</li>
<li className={classes.dd}> Anay Properties</li>
<li className={classes.dd}> Pecific Enginners </li>
<li className={classes.dd}> Kalukhe Warehouse </li>
<li className={classes.dd}>Anashwara Enginers </li>
</ul>
</div>
<div className={classes.dow}>
<ul className={classes.eee}>
<li className={classes.dd}> Kryashindu warehouse</li>
<li className={classes.dd}> Pacepick Engineering </li>
<li className={classes.dd}> Bhaghlaxmi Industries  </li>
<li className={classes.dd}> Lokhande Warehouse</li>
<li className={classes.dd}> Pentagon Enterprise </li>
<li className={classes.dd}>  Press Fab Industries</li>
<li className={classes.dd}> Swanand Foods Pvt. Ltd.</li>
<li className={classes.dd}> John Deere India Pvt. Ltd.  </li>
<li className={classes.dd}>  Kaygee Foam Pvt. Ltd.</li>
<li className={classes.dd}> Willow Polymers Pvt. Ltd. </li>
<li className={classes.dd}> Maks Automotive Pvt. Ltd.  </li>
<li className={classes.dd}> Pranjali Forgings Private Ltd. </li>
<li className={classes.dd}> Rehau Polymers Pvt. Ltd. - Ranjangaion </li>
<li className={classes.dd}> Mersen india Pvt. Ltd. - Ranjangaion  </li>

</ul>

</div>

</div>






</div>

    </div>
  )
}

export default OurClient